import React, { useState } from "react";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import { Link } from "react-router-dom";
import { SidebarData } from "./SidebarData";
import "../App.css";
import { IconContext } from "react-icons";
import { AppBar, Toolbar, Typography } from "@mui/material";

function Navbar() {
  const [sidebar, setSidebar] = useState(false);

  const showSidebar = () => setSidebar(!sidebar);

  return (
    <IconContext.Provider value={{ color: "undefined" }}>
      <AppBar  position="static"  sx={{ backgroundColor: 'gray', height:"50px", paddingBottom:3 }}>
        <Toolbar>
          <Link to="#" className="menu-bars" style={{ marginRight: "16px", marginBotton:4 }}>
            <FaIcons.FaBars onClick={showSidebar} />
          </Link>
          <Typography variant="h5" component="div" sx={{marginBottom: 0.8}}>
            FINGO WIDGET PLAYGROUND
          </Typography>
        </Toolbar>
      </AppBar>
      <nav className={sidebar ? "nav-menu active" : "nav-menu"} style={{zIndex: 100}}>
        <ul className="nav-menu-items" onClick={showSidebar}>
          <li className="navbar-toggle" style={{zIndex: 100}}>
            <Link to="#" className="menu-bars">
              <AiIcons.AiOutlineClose />
            </Link>
          </li>
          {SidebarData.map((item, index) => {
            return (
              <li key={index} className={item.cName}>
                <Link to={item.path}>
                  {item.icon}
                  <span>{item.title}</span>
                </Link>
              </li>
            );
          })}
        </ul>
      </nav>
    </IconContext.Provider>
  );
}

export default Navbar;
