import { createTheme } from '@mui/material/styles';
import typography from './typography';

const theme = createTheme(
    {
        palette: {
        primary: {
            main: '#E62E66',
            light: '#fdeaf0',
            unreadNotification: '#FDEEF3',
            dark: '#a12047',
            mild: '#eb5784',
        },
        secondary: {
            main: '#323232',
        },
        fingoWhite: {
            main: '#FFF',
        },
        error: {
            main: '#323232',
        },
        },
        typography,
    },
    'light',
    );


export default theme;