import React, { useState, useEffect } from 'react';
import sendMessageToIframe from '../helpers/santander';

function Santander() {
  const [token, setToken] = useState('');
  const [open, setOpen] = useState(false);
  const [iframeWidth, setIframeWidth] = useState('1100px');
  const [iframeHeight, setIframeHeight] = useState('574px');

  const handleOpen = () => {
    setOpen(true);
  };

  const handleChange = (event) => {
    setToken(event.target.value);
  };

  const handleClose = () => {
    sendMessageToIframe({ type: 'LOGOUT' });
    setOpen(false);
  };

  const handleOnLoad = () => {
    sendMessageToIframe({
      type: 'LOGIN',
      data: { token },
    });
  };

  const handleWidthChange = (event) => {
    setIframeWidth(event.target.value);
  };

  const handleHeightChange = (event) => {
    setIframeHeight(event.target.value);
  };


  useEffect(() => {
    window.addEventListener('message', function (event) {
      console.log('Message received from the child: ', event.data); // Message received from child
    });
  }, []);

  return (
    <div className="santander">
      <h3>Enrollment Widget</h3>
      <div>
        <p>Token:</p>
        <input type="text" value={token} onChange={handleChange} />
      </div>
      <div className='input-row'>
      <div className='input-group'>
        <p>Width: </p>
        <input type="text" value={iframeWidth} onChange={handleWidthChange} />
      </div>
      <div className='input-group'>
        <p>Height: </p>
        <input type="text" value={iframeHeight} onChange={handleHeightChange} />
      </div>
      </div>

      <div>
        <button onClick={handleOpen} disabled={!token}>
          Abrir iframe
        </button>
        <button onClick={handleClose} disabled={!open}>
          Cerrar iframe
        </button>
      </div>
      {open && (
        <iframe
          id="enrollment-iframe"
          src={process.env.REACT_APP_ENROLLMENT_WIDGET_URI}
          title="enrollment-widget"
          width={iframeWidth}
          height={iframeHeight}
          onLoad={handleOnLoad}
        ></iframe>
      )}
    </div>
  );
}

export default Santander;
