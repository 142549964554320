import React, { useState } from 'react';
import Fingo from 'fingo-factoring-widget';
import { Button, Container, Stack, Box, TextField } from '@mui/material';

const SDK = () => {
  const [widgetToken, setWidgetToken] = useState('eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjb21wYW55X2luZm8iOnsicnV0IjoiNzY1NTE1MzQtMyIsIm5hbWUiOiJFamVtcGxvIFMuQS4ifSwidXNlcl9pbmZvIjp7ImVtYWlsIjoidXN1YXJpbzEwQGV4YW1wbGUuY29tIiwicnV0IjoiMTIzNDU2NzgtOSIsInBob25lX251bWJlciI6Iis1NjkxMjM0NTY3OCIsImZpcnN0X25hbWUiOiJUZXN0IiwibGFzdF9uYW1lIjoiQXBlbGxpZG8ifSwid2lkZ2V0X2tleSI6InRlc3RfdW5rcDBXRVlEbUwwZ2tQd3VDNDgifQ.v0TjvS_CyAHoH4PHcjk4V0uPPv3mrzv0ZbipXjdB_hU');
  const [authToken, setAuthToken] = useState('eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjbGllbnRfaWQiOjJ9.UxFTq_oQVDsKxnYPl-GcYAgRCzQ98v5AuMToX4dfaMU');
  const [fingoWidget, setFingoWidget] = useState(null);

  const handleWidgetTokenChange = (e) => {
    setWidgetToken(e.target.value);
  };

  const handleAuthTokenChange = (e) => {
    setAuthToken(e.target.value);
  };

  const initializeFingoWidget = () => {
    const widget = new Fingo(widgetToken, authToken, 'staging');
    setFingoWidget(widget);
    widget.open();
  };

  return (
    <Container maxWidth={60} sx={{
      backgroundColor: "background.default",
      paddingBottom: 1,
      mt: 1,
      boxShadow: 1,
      height: "100vh",
    }}>
      <Stack spacing={5} sx={{ my: 2, mx: "auto"}} direction="row" >
        <TextField
          label="Widget Token"
          variant="outlined"
          value={widgetToken}
          size='small'
          onChange={handleWidgetTokenChange}
          fullWidth
        />
        <TextField
          label="Auth Token"
          variant="outlined"
          value={authToken}
          size='small'
          onChange={handleAuthTokenChange}
          fullWidth
        />
        <Box sx={{py: 'auto', alignContent: 'center'}}>
          <Button size="small" variant="contained" color="primary" onClick={initializeFingoWidget}
          sx={{borderRadius: 4.5, minWidth: 80, maxHeight: 30}}>
            Iniciar
          </Button>
        </Box>

          <Box sx={{py: 'auto', alignContent: 'center'}}>
            <Button 
              size="small" 
              variant="contained" 
              color="primary" 
              onClick={() => fingoWidget && fingoWidget.open()}
              disabled={!fingoWidget}
              sx={{borderRadius: 4.5, minWidth: 80, maxHeight: 30}}
              >
              Abrir
            </Button>
            </Box>
            <Box sx={{py: 'auto', alignContent: 'center'}}>

          <Button 
            size="small" 
            variant="outlined" 
            color="secondary" 
            onClick={() => fingoWidget && fingoWidget.close()}
            disabled={!fingoWidget}
            sx={{borderRadius: 4.5, minWidth: 80, maxHeight: 30}}
          >
            Cerrar
          </Button>
          </Box>

      </Stack>
        <Box sx={{
          marginTop: 0,
          mx: 10,
          zIndex: 10,
        }} id="iframe-container"></Box>
    </Container>
  );
};

export default SDK;
