import React from 'react';
import Fingo from 'fingo-factoring-widget';
import { Button, Container, Stack, Box } from '@mui/material';

const WIDGET_TOKEN = 'test_unkp0WEYDmL0gkPwuC48';
const AUTH_TOKEN =
'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjbGllbnRfaWQiOjN9.GMUrmxY7fh4d-XgsnnUpH7lEwsdA39L-FaO9iETb2CY';
const COMPANY_INFO = { name: 'Stark Industries Inc.', rut: '76551534-3' };
  const USER_INFO = {
  firstName: 'Raimundo',
  lastName: 'Orellana',
  rut: null,
  email: 'raimundo19@fingo.cl',
  phoneNumber: null,
};



const Skualo = () => {
  const FingoWidget = new Fingo(
    WIDGET_TOKEN,
    AUTH_TOKEN,
    USER_INFO,
    COMPANY_INFO,
  );

  return (
      <Container>
        <Stack spacing={5} sx={{ my: 1.5, justifyContent: 'center' }} direction="row">
          <Box sx={{py: 'auto', alignContent: 'center'}}>
            <Button size="small"
            variant="contained"
            color="primary"
            onClick={() => FingoWidget.open()}
            sx={{borderRadius: 4.5, minWidth: 80, maxHeight: 30}}
              >
              Abrir Widget
            </Button>
          </Box>
          <Box sx={{py: 'auto', alignContent: 'center'}}>
            <Button size="small"
            variant="outlined"
            color="secondary"
            onClick={() => FingoWidget.close()}
            sx={{borderRadius: 4.5, minWidth: 80, maxHeight: 30}}
              >
              Cerrar Widget
            </Button>
          </Box>
        </Stack>
      </Container>
  );
};

export default Skualo;
