import React from 'react';
import { createRoot } from 'react-dom/client';
import { createBrowserRouter, RouterProvider, Outlet, Navigate } from 'react-router-dom';
import Santander from './routes/Santander';
import Navbar from './components/Navbar';
import './App.css';
import SDK from './routes/SDK';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme/theme';
import Skualo from './routes/Skualo';

const AppLayout = () => (
  <ThemeProvider theme={theme}>
    <Navbar />
    <Outlet />
  </ThemeProvider>
);

const router = createBrowserRouter([
  {
    element: <AppLayout />,
    children: [
      {
        path: '/',
        element: <Santander />,
      },
      {
        path: '/sdk',
        element: <SDK />,
      },
      {
        path: 'alternativo',
        element: <Skualo />,
      },
      {
        path: '*',
        element: <Navigate to="/" replace />,
      }
    ],
  },
]);

createRoot(document.getElementById('root')).render(<RouterProvider router={router} />);
