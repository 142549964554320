const typography = {
    fontFamily: 'Be Vietnam',
    h1: {
      fontWeight: 'bold',
      fontFamily: 'Be Vietnam',
      fontSize: 25,
      lineHeight: 1,
    },
    h2: {
      fontWeight: '400',
      fontFamily: 'Be Vietnam',
      fontSize: 14,
      lineHeight: 1.1,
    },
    h3: {
      fontWeight: '500',
      fontSize: 25,
      lineHeight: 1.1,
    },
    h4: {
      fontWeight: '400',
      fontSize: 20,
      lineHeight: 1.2,
    },
    h5: {
      fontWeight: '400',
      fontSize: 16,
      lineHeight: 1.2,
    },
    h6: {
      fontWeight: '400',
      fontSize: 14,
      lineHeight: 1.2,
    },
    h7: {
      fontWeight: '500',
      fontSize: 20,
      lineHeight: 1.2,
    },
    body1: {
      fontSize: 12,
      fontWeight: '400',
      lineHeight: 1.2,
    },
    body2: {
      fontSize: '12',
      fontWeight: '300',
      lineHeight: 1.25,
    },
    caption: {
      fontSize: 12,
      lineHeight: 1.2,
      color: '#ABABAB',
    },
    button: {
      textTransform: 'none',
      height: 50,
      fontSize: 14,
    },
    subtitle1: {
      height: 'auto',
      fontSize: 12,
      WebkitLineClamp: 2,
      display: '-webkit-box',
      overflow: 'hidden',
      WebkitBoxOrient: 'vertical',
      lineHeight: '0.8rem !important',
    },
    subtitle3: {
      height: 'auto',
      fontSize: 11,
      WebkitLineClamp: 2,
      display: '-webkit-box',
      overflow: 'hidden',
      WebkitBoxOrient: 'vertical',
      lineHeight: '0.8rem !important',
      fontWeight: 400,
    },
    formLabel: {
      fontSize: '14',
      fontWeight: '400',
      display: '-webkit-box',
      marginBottom: '5px',
    },
    cardHeader: {
      fontSize: 14,
      fontWeight: 700,
      lineHeight: 1.2,
    },
    subNavItem: {
      fontWeight: '200',
      fontFamily: 'Plus Jakarta Sans',
      fontSize: 13,
      lineHeight: 1.1,
    },
    info: {
      maxWidth: 200,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      WebkitLineClamp: '1',
      WebkitBoxOrient: 'vertical',
      fontWeight: 500,
    },
    error: {
      fontSize: 14,
      lineHeight: 1.2,
      fontWeight: 500,
    },
  };
  
  export default typography;
  